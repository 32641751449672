import BaseModel from './BaseModel';
//import firebase from 'firebase/app';
//import 'firebase/firestore';

import { /*Configs as ConfigsData,*/ DataConverter } from '@/FirestoreDataConverters/Configs.js';


export default class Configs extends BaseModel {
  constructor(options) {
    super(options);
  }
  getActive() {
    return this.db.collection('configs')
    .withConverter(DataConverter)
    .where('status', '==', 'active')
  }
  update(id, obj) {
    return this.db.collection('configs').doc(id).update(obj);
  }
}