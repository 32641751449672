import BaseModel from './BaseModel';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { Landing as LandingData, DataConverter as LandingDataConverter } from '@/FirestoreDataConverters/Landing.js';


export default class Landing extends BaseModel {
  constructor(options) {
    super(options);
  }
  all() {
    return this.db.collection('landings').withConverter(LandingDataConverter).where('deleted', '==', 0);
  }
  getActives() {
    return this.db.collection('landings')
    .withConverter(LandingDataConverter)
    .where('status', '==', 'active')
    .where('deleted', '==', 0);
  }
  update(id, obj) {
    return this.db.collection('landings').doc(id).update(obj);
  }
  set(id, data, options) {
    return this.db.collection('landings').doc(id).set(data, options);
  }
  findUrl(fullUrl/*, lang*/) {
    return this.db.collectionGroup('landingUrls')
      .where('fullUrl', '==', fullUrl)
      .where('deleted', '==', 0)
  }
  add(data, language) {
    return new Promise((resolve, reject) => {
      data.createdDate = firebase.firestore.Timestamp.fromDate(new Date);
      this.db.collection('landings').withConverter(LandingDataConverter)
      .add(new LandingData(data)).then(docRef => {
        docRef.collection('landingLanguages').doc(language.id).set({
          name: data.name,
          title: data.title,
          metadescription: data.metadescription,
          language: language.name,
          description: data.description,
        }).then(() => resolve(docRef))
      })
      .catch(error => reject(error))
    })
  }
  async deleteUrls(landingId) {
    this.db.collection('landings').doc(landingId).collection('landingUrls').get().then(snap => {
      snap.forEach(async (item) => {
        await item.ref.update({'deleted':1})
      })
    })
  }
  findByLandingId(id) {
    return this.db.collection('landings').doc(id).withConverter(LandingDataConverter);
  }
  findUrlByLanding(landingId, lang) {
    
    return this.db.collectionGroup('landingUrls')
      .where('landingId', '==', landingId)
      .where('deleted', '==', 0)
      .where('language', '==', lang);
  }
  
}