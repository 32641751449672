<template>
  <div>
    <Loading :active="loading" :fullPage="true" />
    <portal to="title-page">
      <h1>Landings del home</h1>
    </portal>
    <v-card>
      <v-card-text>
        <h3>Landings disponibles</h3>
        <p>Seleccione las landings que se monstraran en el home</p>
        <ul class="list-extras">
          <li v-for="(e, key) in landings" :key="key" class="d-flex">
            <v-checkbox :id="`check${key}`" v-model="selectedLandings" :value="e.id"></v-checkbox>
            <label :for="`check${key}`" class="label-check">
              {{ e.name }}
            </label>
          </li>
        </ul>
        <v-btn
          color="primary"
          class="mr-4"
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Landing from "@/models/Landing.js";
import Configs from "@/models/Configs.js";
export default {
  name: 'AdminCMSHomeLandings',
  data() {
    return {
      landings: null,
      config: null,
      landingModel: new Landing,
      configsModel: new Configs,
      loading: true,
      selectedLandings: [],
      snackbar: {
          show: false,
          color: '',
          message: ''
        },
    }
  },
  methods: {
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
    save() {
      this.loading = true
      this.configsModel.update(this.config.id, {
        homeLandings: this.selectedLandings
      }).then(() => {
        this.showSnackbarSuccess('Las landings se guardaron correctamente')
        this.loading = false
      })
      .catch(() => {
        this.showSnackbarError('Error al guardar los landings')
        this.loading = false
      })
    }
  },
  mounted() {
    this.landings = []
    this.configsModel.getActive().get().then((snap) => {
      this.config = {}
      snap.forEach((doc) => {
        this.config = doc.data()
      })
      this.landingModel.getActives().onSnapshot((snap) => {
        this.loading = false,
        snap.forEach((doc) => {
          this.landings.push({
            ... doc.data()
          })
          if (!(this.config.homeLandings.indexOf(doc.id) < 0)) {
            this.selectedLandings.push(doc.id)
          }
        })
      });
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
}
</script>

<style>
  .list-extras{
    list-style: none;
  }
  .label-check{
    display: flex;
    align-items: center;
  }
</style>