class Landing {
  constructor(data) {
    this.id = data.id;
    this.name = data.name || '';
    this.status = data.status || 'inactive';
    this.type = data.type || 'register';
    this.tours = data.tours || null;
    this.extras = data.extras || [];
    this.sliderDesktopImages = data.sliderDesktopImages || [];
    this.sliderTabletImages = data.sliderTabletImages || [];
    this.sliderPhoneImages = data.sliderPhoneImages || [];
    this.backgroundDesktop = data.backgroundDesktop || null;
    this.backgroundTablet = data.backgroundTablet || null;
    this.backgroundPhone = data.backgroundPhone || null;
    this.thumbDesktop = data.thumbDesktop || null;
    this.thumbTablet = data.thumbTablet || null;
    this.thumbPhone = data.thumbPhone || null;
    this.createdDate = data.createdDate;
    // this.pictureTour = pictureTour || '';
  }

}
var DataConverter = {
  // Firestore data converter
  toFirestore: function(landing) {
    return {
      name: landing.name,
      status: landing.status,
      createdDate: landing.createdDate,
      tours: landing.tours || null,
      extras: landing.extras || [],
      deleted: 0,
      type: landing.type,
    }
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new Landing(data)
  }
}

export { Landing, DataConverter }