class Configs {
  id;
  constructor(data) {
    this.id = data.id
    this.homeLandingsTitle = data.homeLandingsTitle
    this.homeLandings = data.homeLandings
    this.homeTours = data.homeTours
    this.status = data.status
  }
}
var DataConverter = {
  toFirestore: function() {
    return {
    }
  },
  fromFirestore: function(snapshot, options) {
    let data = snapshot.data(options);
    data.id = snapshot.id
    return new Configs(data)
  }
}
export {Configs, DataConverter} 